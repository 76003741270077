/* Bootstrap  */
// import "bootstrap";
// import { Modal } from 'bootstrap'
import "slick-carousel";

/* Custom SCSS */
import "./index.scss";

/* SwiperJS */
// import Swiper /*, { Navigation, Pagination, Scrollbar }*/ from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

import navbar from "./js/navbar";
import blockSliderNews from "./js/blockSliderNews";
import cardGallery from "./js/cardGallery";

navbar();
blockSliderNews();
cardGallery();
